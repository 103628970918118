<template>
    <Header :title="$t('Calculate your calorieneed')">
        <template v-slot:button-start>
            <button @click="$router.go(-1)" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>
    </Header>

    <div class="container">
        <div class="cms-body">
            <p v-html="$t('Use the calculator below to calculate your daily calorie requirement. In the table with results you can see how many calories you can eat per day to <strong>maintain your weight</strong> and how many calories you can eat per day to lose <strong>half a kilo per week</strong >.')"></p>

            <div class="fields">
                <div
                    class="field"
                    v-for="(field, field_key) in settings"
                    :key="field_key"
                >
                    <label :for="field_key">{{ field.label }}</label>
                    <div
                        :class="`input ${errors[field_key] ? 'has-error' : ''}`"
                    >
                        <div class="input-content">
                            <template v-if="field.type === 'number'">
                                <input
                                    type="number"
                                    :id="field_key"
                                    :placeholder="field.placeholder"
                                    v-model="values[field_key]"
                                />
                            </template>
                            <template v-else-if="field.type === 'options'">
                                <select
                                    :id="field_key"
                                    v-model="values[field_key]"
                                >
                                    <option
                                        v-for="(
                                            option, option_key
                                        ) in field.options"
                                        :key="option_key"
                                        :value="option_key"
                                    >
                                        {{ option }}
                                    </option>
                                </select>
                            </template>
                            <template v-else>
                                <input
                                    type="text"
                                    :id="field_key"
                                    v-model="values[field_key]"
                                />
                            </template>
                        </div>
                        <div class="error" v-if="errors[field_key]">
                            {{ errors[field_key] }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-10">
                <button class="btn -accent -lg" @click="calculate">
                    <span>{{ $t("Calculate") }}</span>
                </button>
            </div>

            <div class="form-result" v-if="result">
                <p>
                    {{ $t("For a") }}
                    <strong>
                        {{ result.fields.gender === "0" ? $t("Female") : $t("Male") }}
                    </strong>
                    {{ $t("from") }}
                    <strong>{{ $t("{age} years", { age: result.fields.age } ) }}</strong>
                    {{ $t("with a length of") }}
                    <strong>{{ $t("{height} cm", { height: result.fields.height } ) }}</strong>
                    {{ $t("and a weight of") }}
                    <strong>{{ $t("{weight} kilo", { weight: result.fields.weight } ) }}</strong>
                    {{ $t("the following values apply.") }}
                </p>
                <ul>
                    <li :class="[result.fields.activity === 0 && 'active']">
                        <span>{{ $t("When you don't sport") }}</span>
                        <strong>{{ result.activities.none }} {{ $t("kcal") }}</strong>
                    </li>
                    <li :class="[result.fields.activity === 2 && 'active']">
                        <span>{{ $t("With {min} to {max} hours of sport per week:", { min: 1, max: 3 }) }}</span>
                        <strong>{{ result.activities.small }} {{ $t("kcal") }}</strong>
                    </li>
                    <li :class="[result.fields.activity === 4 && 'active']">
                        <span>{{ $t("With {min} to {max} hours of sport per week:", { min: 3, max: 5 }) }}</span>
                        <strong>{{ result.activities.medium }} {{ $t("kcal") }}</strong>
                    </li>
                    <li :class="[result.fields.activity === 6 && 'active']">
                        <span>{{ $t("With {min} to {max} hours of sport per week:", { min: 5, max: 7 }) }}</span>
                        <strong>{{ result.activities.large }} {{ $t("kcal") }}</strong>
                    </li>
                </ul>

                <p v-html="$t('If you want to lose weight, you should eat {result} kcal less', { result: result.kcalActivity })"></p>
            </div>
        </div>
    </div>
</template>

<script>
import PG from "@/lib/PG";
import Validator from "@/lib/Validator";
import Translations from "@/lib/Translations";

import Header from "@/components/Header";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

const $t = Translations.Get;

export default {
    name: "Kcal",
    components: {
        Header,
        iconLeft
    },
    data() {
        return {
            errors: {},
            result: null,
            values: {
                gender: null,
                height: null,
                weight: null,
                age: null,
                activity: null,
            },
            settings: {
                gender: {
                    label: this.$t("Gender"),
                    type: "options",
                    options: {
                        0: this.$t("Female"),
                        1: this.$t("Male"),
                    },
                    validate(val) {
                        return ["0", "1"].includes(val);
                    },
                },
                height: {
                    label: this.$t("Height in cm"),
                    placeholder: this.$t("E.g. 170"),
                    type: "number",
                    validate(val) {
                        if (val < 100 || val > 250) {
                            return $t("Height must be between 100 and 250");
                        }
                        return true;
                    },
                },
                weight: {
                    label: this.$t("Weight in kg"),
                    placeholder: this.$t("E.g. 70"),
                    type: "number",
                    validate(val) {
                        if (!(val >= 30 && val <= 300)) {
                            return $t("Weight must be between 30 and 300");
                        }
                        return true;
                    },
                },
                age: {
                    label: this.$t("Age in years"),
                    placeholder: this.$t("E.g. 30"),
                    type: "number",
                    validate(val) {
                        if (!(val >= 1 && val <= 150)) {
                            return $t("Age must be between 1 and 150");
                        }
                        return true;
                    },
                },
                activity: {
                    label: this.$t("How often do you exercise?"),
                    type: "options",
                    options: {
                        0: this.$t("I don't exercise"),
                        2: this.$t("1 to 3 hours a week"),
                        4: this.$t("3 to 5 hours a week"),
                        6: this.$t("6 to 7 hours a week"),
                    },
                    validate(val) {
                        return ["0", "2", "4", "6"].includes(val);
                    },
                },
            },
        };
    },
    methods: {
        calculate() {
            let schema = Object.assign({ ...this.settings });
            Object.keys(schema).map((key) => {
                schema[key] = schema[key].validate;
            });

            this.errors = Validator.Validate(this.values, schema);

            if (Object.values(this.errors).length > 0) {
                this.$toast({
                    message: Object.values(this.errors)[0],
                    type: "error",
                });
                return;
            }

            this.result = PG.CalculateKcalNeed(
                this.values.weight,
                this.values.height,
                this.values.age,
                this.values.gender,
                this.values.activity
            );
        },
    },
};
</script>

<style lang="scss">
@import "../index";
</style>
