<template>
    <div class="cms-body" v-html="getHtml" @click="handleClicks" />
</template>

<script>
import PG from "@/lib/PG";

export default {
    name: "CMSBody",
    props: {
        html: {
            type: String,
            required: true,
        },
    },
    computed: {
        getHtml() {
            if (!this.html) {
                return "";
            }

            return this.html.replaceAll(
                /href="\/ionic-pages\/(.*?)"/g,
                this.convertIonicPage
            );
        },
    },
    methods: {
        convertIonicPage(m, $1) {
            let page = PG.ConvertIonicToRoute($1) || `ionic-pages/${$1}`;

            return `href="${page}"`;
        },
        handleClicks(event) {
            let { target } = event;
            while (target && target.tagName !== "A") target = target.parentNode;
            if (
                target &&
                target.matches(".cms-body a:not([href*='://'])") &&
                target.href
            ) {
                const {
                    altKey,
                    ctrlKey,
                    metaKey,
                    shiftKey,
                    button,
                    defaultPrevented,
                } = event;

                if (metaKey || altKey || ctrlKey || shiftKey) return;
                if (defaultPrevented) return;
                if (button !== undefined && button !== 0) return;
                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute("target");
                    if (/\b_blank\b/i.test(linkTarget)) return;
                }

                const url = new URL(target.href);
                const to = url.pathname;
                if (window.location.pathname !== to && event.preventDefault) {
                    event.preventDefault();
                    this.$router.push(to);
                }
            }
        },
    },
};
</script>
