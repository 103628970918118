<script>
export default {
    mounted() {
        if (!document.URL.includes("#/update")) {
            this.$toast({
                type: "error",
                message: this.$t("Page not found"),
            });
        }
        this.$router.push({
            path: "/",
        });
    },
};
</script>
