import HTTPClient from "@/lib/HTTPClient";
import CMSPage from "@/models/CMSPage";

const Pages = {};

Pages.GetPageBySlug = async function(slug = null) {
    if (!slug) {
        return null;
    }

    const { data, status } = await HTTPClient.Get(`/pages-v2/${slug}`);

    if (status != 200) {
        return null;
    }

    return new CMSPage(data);
};

Pages.CreateContactTicket = async function(form_data) {
    const { data, status } = await HTTPClient.Post("/ticket/create", {
        data: form_data,
        headers: { "Content-Type": "multipart/form-data" },
    });

    if (status != 200) {
        return data;
    }

    return data;
};

export default Pages;
