<template>
    <Header
        :title="page ? page.getTitle() : 'Loading..'"
    >
        <template v-slot:button-start>
            <button @click="$router.go(-1)" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>
    </Header>

    <div class="container" v-if="page">
        <cms-body :html="page.getText()" />
    </div>
</template>

<script>
import PagesApi from "@/lib/api/Pages";
import Header from "@/components/Header";
import CmsBody from "@/components/CmsBody";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    name: "CMSPage",
    data() {
        return {
            page: null
        };
    },
    components: {
        Header,
        CmsBody,
        iconLeft,
    },
    computed: {
        slug() {
            return this.$route.params.slug.replace(/\//g, ",");
        }
    },
    async mounted() {
        this.page = await PagesApi.GetPageBySlug(this.slug);
    }
};
</script>

<style lang="scss">
@import "index";
@import "faq";
</style>
