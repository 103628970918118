<template>
    <Header :title="$t('Calculate BMI')">
        <template v-slot:button-start>
            <button @click="$router.go(-1)" class="btn -icon-only">
                <icon-left />
            </button>
        </template>
    </Header>

    <div class="container">
        <div class="cms-body">
            <p>{{ $t("Use the form below to calculate your BMI.") }}</p>
            <div class="fields">
                <div class="field">
                    <label for="length">
                        {{ $t("Length (in cm)") }}
                    </label>
                    <div class="input">
                        <div class="input-content">
                            <input
                                type="number"
                                id="length"
                                v-model="values.length"
                                :placeholder="$t('Fill in your length')"
                            />
                        </div>
                    </div>
                </div>
                <div class="field">
                    <label for="weight">
                        {{ $t("Weight (in kg)") }}
                    </label>
                    <div class="input">
                        <div class="input-content">
                            <input
                                type="number"
                                id="weight"
                                v-model="values.weight"
                                :placeholder="$t('Fill in your weight')"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <bmi-result
                class="mt-10"
                v-if="result"
                :bmi="result"
                :length="result_length"
            />

            <div class="button-container my-4 flex align-center justify-center">
                <button class="button -lg -accent" @click="calculate">
                    {{ $t("Calculate BMI") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import PG from "@/lib/PG";
import Header from "@/components/Header";
import BmiResult from "@/components/BmiResult";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    name: "Bmi",
    components: {
        Header,
        BmiResult,
        iconLeft,
    },
    data() {
        return {
            error: null,
            values: {
                length: "",
                weight: "",
            },
            result: null,
            result_length: null,
        };
    },
    methods: {
        calculate() {
            const { length, weight } = this.values;

            if (!length || !weight) {
                this.$toast({
                    message: this.$t("Please fill in all fields"),
                    type: "error",
                });
                return;
            }

            this.result_length = length;
            this.result = PG.CalculateBMI(weight, length);
        },
    },
};
</script>

<style lang="scss">
@import "../index";
</style>
