<template>
    <Header :title="page ? page.getTitle() : 'Loading..'">
        <template v-slot:button-start>
            <button @click="$router.go(-1)" class="btn -icon-only">
                <icon-left />
            </button>
        </template>
    </Header>

    <div class="container" v-if="page">
        <cms-body :html="page.getText()" />
        <div class="cms-body">
            <form
                v-if="!showThanks"
                method="post"
                @submit.prevent="submit"
                class="fields"
            >
                <div class="field">
                    <label for="subject">{{ $t("Subject") }}</label>
                    <div
                        :class="`input ${
                            errors.subject ? 'has-error' : ''
                        }`"
                    >
                        <div class="input-content">
                            <select v-model="values.subject" id="subject">
                                <option value="" disabled selected>
                                    {{ $t("Select an option") }}
                                </option>
                                <option
                                    v-for="(subject, index) in subjects"
                                    :key="index"
                                    :value="subject"
                                >
                                    {{ subject }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label for="body">{{ $t("Question") }}</label>
                    <div :class="`input ${errors.body ? 'has-error' : ''}`">
                        <div class="input-content">
                            <textarea
                                id="body"
                                v-model="values.body"
                                :placeholder="$t('Your question')"
                            />
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label for="file">{{ $t("Files") }}</label>
                    <div :class="`input ${errors.file ? 'has-error' : ''}`">
                        <div class="input-content">
                            <input
                                type="file"
                                name="file"
                                id="file"
                                ref="file"
                                @change="handleFileUpload"
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="button-container my-4 flex align-center justify-center"
                >
                    <button class="button -lg -accent -icon" @click.prevent="submit">
                        <iconPlane />
                        {{ $t("Send") }}
                    </button>
                </div>
            </form>
            <div class="thanks" v-if="showThanks">
                <h2>{{ $t("Thanks!") }}</h2>
                <p>
                    {{
                        $t(
                            "You'll receive a response within 2 days for your comment or question."
                        )
                    }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import PagesApi from "@/lib/api/Pages";
import Validator from "@/lib/Validator";
import Header from "@/components/Header";
import CmsBody from "@/components/CmsBody";

import iconPlane from "@/assets/icons/icon-plane.svg?inline";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    name: "CMSPage",
    data() {
        return {
            page: null,
            slug: "contact",
            subjects: [this.$t("Help with diet"), this.$t("Other questions")],
            showThanks: false,
            values: {
                subject: "",
                body: "",
                file: null,
            },
            errors: {
                subject: null,
                body: null,
                file: null,
            },
            validations: {
                subject: Validator.NotEmpty,
                body: Validator.NotEmpty,
            }
        };
    },
    components: {
        Header,
        CmsBody,
        iconPlane,
        iconLeft,
    },
    async mounted() {
        this.page = await PagesApi.GetPageBySlug(this.slug);
    },
    methods: {
        async submit() {
            this.errors = Validator.Validate(this.values, this.validations);

            if (Object.values(this.errors).length > 0) {
                this.$toast({
                    message: Object.values(this.errors)[0],
                    type: "error",
                });
                return;
            }

            let formData = new FormData();
            formData.append("subject", this.values.subject);
            formData.append("text", this.values.body);
            formData.append("file", this.values.file);

            await PagesApi.CreateContactTicket(formData);

            this.showThanks = true;
        },
        handleFileUpload() {
            this.values.file = this.$refs.file.files[0];
        },
    },
};
</script>

<style lang="scss">
@import "../index";
</style>
